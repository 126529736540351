<template>
    <div class="px-3 mt-0">
        <div class="row">
            <div class="col-md-12">
                <div class="card form mb-0">
                    <div class="card-body">
                        <div v-if="loading">
                            <LoadingTableForm
                                :cols="2"
                                :rows="6"
                                class="table-bordered bg-white" />
                        </div>
                        <b-form v-else @submit.prevent="submitForm">
                            <table
                                class="table table-bordered table-wrap table-form">
                                <thead>
                                    <tr class="bg-dark bg-gradient">
                                        <th
                                            class="text-light text-start"
                                            colspan="6">
                                            {{ $t('block ip') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th class="bg-light bg-soft">
                                            {{ $t('ip address') }}
                                        </th>
                                        <td>
                                            <input
                                                v-model="pl.ip"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid':
                                                        (submitted &&
                                                            v$.pl.ip.required
                                                                .$invalid) ||
                                                        (pl.ip &&
                                                            v$.pl.ip.validIP
                                                                .$invalid),
                                                    'is-valid':
                                                        pl.ip &&
                                                        !v$.pl.ip.$error,
                                                }" />
                                            <!-- <div
                                                v-for="(item, index) in v$.pl.ip.$errors"
                                                :key="index"
                                                class="invalid-feedback"
                                            >
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div> -->

                                            <div
                                                v-if="
                                                    submitted &&
                                                    v$.pl.ip.required.$invalid
                                                "
                                                class="invalid-feedback">
                                                {{ v$.pl.ip.required.$message }}
                                            </div>
                                            <div
                                                v-if="
                                                    pl.ip &&
                                                    v$.pl.ip.validIP.$invalid
                                                "
                                                class="invalid-feedback">
                                                {{ v$.pl.ip.validIP.$message }}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="3">
                                            <div ref="quillWrapper">
                                                <QuillEditor
                                                    contentType="html"
                                                    :placeholder="
                                                        $t('enter note here')
                                                    "
                                                    v-model:content="pl.note"
                                                    :class="`ql-container ql-snow ${
                                                        submitted &&
                                                        v$.pl.note.$error
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`" />
                                            </div>
                                            <div
                                                class="invalid-feedback"
                                                :style="`${
                                                    submitted &&
                                                    v$.pl.note.required.$invalid
                                                        ? 'display: block'
                                                        : ''
                                                }`">
                                                {{
                                                    v$.pl.note.required.$message
                                                }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div
                                                class="table-responsive mb-3 border"
                                                style="
                                                    max-height: 200px;
                                                    min-height: 200px;
                                                "
                                                v-if="viewMode">
                                                <table
                                                    class="table table-bordered table-hover table-striped table-sm">
                                                    <thead>
                                                        <tr
                                                            class="bg-white"
                                                            style="
                                                                position: sticky;
                                                                top: -1px;
                                                                z-index: 2;
                                                            ">
                                                            <th colspan="2">
                                                                <i
                                                                    class="bx bxs-user me-1"></i
                                                                >{{
                                                                    $t(
                                                                        'connected users'
                                                                    )
                                                                }}
                                                                <span
                                                                    class="text-info ms-3"
                                                                    >{{
                                                                        connected_users.length
                                                                    }}</span
                                                                >
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(
                                                                c, i
                                                            ) in connected_users"
                                                            :key="c._id">
                                                            <td
                                                                class="text-center">
                                                                {{ i + 1 }}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    class="badge"
                                                                    :class="{
                                                                        'bg-warning':
                                                                            c.role ==
                                                                            'admin',
                                                                        'bg-success':
                                                                            c.role ==
                                                                            'agent',
                                                                        'bg-primary':
                                                                            c.role ==
                                                                            'player',
                                                                    }"
                                                                    >{{
                                                                        c.role
                                                                    }}</span
                                                                >
                                                                {{ c.username }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center">
                                <button type="submit" class="btn btn-danger">
                                    {{ $t('save') }}
                                </button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTableForm from '@/components/loading-table-form';

import Switches from 'vue-switches';
import { mapActions, mapGetters } from 'vuex';
import datetime from '../../../../mixins/datetime';
import number from '../../../../mixins/number';
import string from '../../../../mixins/string';
import convert from '../../../../mixins/convert';
import ui from '../../../../mixins/ui';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Switches,
        Multiselect,
        LoadingTableForm,
    },
    mixins: [datetime, number, string, convert, ui],
    data() {
        return {
            title: null,
            submitted: false,
            loading: false,
            _id: this.$route.query.id,
            pl: {
                ip: null,
                note: null,
            },
            connected_users: [],
            quillHeight: 0,
        };
    },
    validations() {
        return {
            pl: {
                ip: {
                    required: helpers.withMessage('required', required),
                    validIP: helpers.withMessage(
                        'must be a valid ip address',
                        function (value) {
                            const regex = /\b(?:\d{1,3}\.){3}\d{1,3}\b/;
                            return (
                                value && helpers.req(value) && regex.test(value)
                            );
                        }
                    ),
                },
                note: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    computed: {
        viewMode() {
            return this._id != null;
        },
        forms() {
            var m_fd = this.formDataToObject(this.objectToFormData(this.pl));
            var o_fd = this.formDataToObject(
                this.objectToFormData(this.pl_origin)
            );
            var f_fd = new FormData();
            var rows = '';
            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];
                    if (!this.viewMode) {
                        // CREATE MODE
                        if (m_fd[key] != null) {
                            f_fd.append(key, m_value);
                        }
                    } else {
                        // EDIT MODE
                        if (
                            m_fd[key] != o_fd[key] &&
                            !['agent[commissions][enabled]'].includes(key)
                        ) {
                            f_fd.append(key, m_value);

                            rows += `<tr>
                                    <td>${this.arrayToArrows(key)}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key]
                                        }
                                    </td>
                                    <td class="${
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key]
                                        }
                                    </td>
                                </tr>`;
                        }
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    watch: {
        'pl.ip'() {
            this.v$.pl.ip.$touch();
        },
        'pl.note'(newValue) {
            this.quillHeight = this.$refs.quillWrapper.clientHeight - 216;
            if (newValue == '<p><br></p>') {
                this.pl.note = null;
            }
        },
        quillHeight(newVal, oldVal) {
            var addedHeight = newVal - oldVal;
            window.resizeTo(
                window.outerWidth,
                window.outerHeight + addedHeight
            );
        },
    },
    methods: {
        ...mapActions('ticket', {
            templateCreate: 'templateCreate',
        }),
        ...mapActions('ipBlock', {
            ipBlockCreate: 'create',
            ipBlockUpdate: 'update',
            ipBlockView: 'view',
        }),
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            } else {
                if (this.viewMode && !this.forms.changes) {
                    Swal.fire({
                        icon: 'info',
                        title: this.$t('no changes made'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }

                var fd = new FormData();

                fd.append('ip', this.pl.ip);
                fd.append('note', this.pl.note);

                const res = !this.viewMode
                    ? await this.ipBlockCreate({ form_data: fd })
                    : await this.ipBlockUpdate({
                          form_data: this.formDataToUrlEncoded(fd),
                          id: this._id,
                      });

                var title = {
                    create: res.success
                        ? this.$t('blocked ip has been created')
                        : this.$t('creating failed'),
                    update: res.success
                        ? this.$t('blocked ip has been updated')
                        : this.$t('updating failed'),
                };

                if (res) {
                    if (res.success && !this.viewMode) {
                        this.reset();
                    } else {
                        this.getDetails();
                    }
                    Swal.fire({
                        title: this.viewMode ? title.update : title.create,
                        icon: res.success ? 'success' : 'error',
                        timer: 2000,
                    });
                }
            }
        },
        async getDetails() {
            if (!this._id) {
                // this.pl_origin = JSON.parse(JSON.stringify(this.pl))
                return;
            }
            this.submitted = false;
            const data = await this.ipBlockView(this._id);

            this.pl.ip = data.ip;
            this.pl.note = await data.note;
            this.connected_users = data.connected_users;
            this.pl_origin = JSON.parse(JSON.stringify(data));
        },
        reset() {
            this.submitted = false;
            this.pl.ip = null;
            this.pl.note = null;
            document.querySelector('.ql-editor').innerHTML = null;
        },
    },
    mounted() {
        this.getDetails();
        window.resizeTo(window.outerWidth, this.viewMode ? 725 : 505);
    },
};
</script>
